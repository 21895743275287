<template>
  <v-btn
    v-if="url"
    v-bind="$attrs"
    v-on="$listeners"
    depressed
    color="grey lighten-3"
    rounded
    small
    :href="url"
    target="_blank"
    @click.stop
  >
    <v-icon size="medium" left>mdi-download</v-icon>
    {{ $t("buttons.download") }}
  </v-btn>
</template>

<script>
  /**
   * @project idenfit
   * @developer Halil Kılıçarslan
   * @description FileDownloadButton Component Logic
   * @date 17.07.2020
   */
  export default {
    inheritAttrs: false,
    name: "FileDownloadButton",
    props: {
      url: {
        type: String,
        required: false
      }
    }
  };
</script>
